/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as WaitlistImport } from './routes/waitlist'
import { Route as VerifyUpdateEmailImport } from './routes/verify-update-email'
import { Route as VerifyEmailImport } from './routes/verify-email'
import { Route as TeamInvitationImport } from './routes/team-invitation'
import { Route as SignupImport } from './routes/signup'
import { Route as ResetPasswordImport } from './routes/reset-password'
import { Route as RecoverPasswordImport } from './routes/recover-password'
import { Route as LoginImport } from './routes/login'
import { Route as LayoutImport } from './routes/_layout'
import { Route as LayoutIndexImport } from './routes/_layout/index'
import { Route as LayoutSettingsImport } from './routes/_layout/settings'
import { Route as LayoutDeviceImport } from './routes/_layout/device'
import { Route as LayoutTeamIndexImport } from './routes/_layout/$team/index'
import { Route as LayoutTeamsNewImport } from './routes/_layout/teams/new'
import { Route as LayoutTeamsAllImport } from './routes/_layout/teams/all'
import { Route as LayoutTeamSettingsImport } from './routes/_layout/$team/settings'
import { Route as LayoutTeamNewAppImport } from './routes/_layout/$team/new-app'
import { Route as LayoutTeamAppsIndexImport } from './routes/_layout/$team/apps/index'
import { Route as LayoutTeamAppsAppSlugIndexImport } from './routes/_layout/$team/apps/$appSlug/index'
import { Route as LayoutTeamAppsAppSlugDeploymentsDeploymentIdImport } from './routes/_layout/$team/apps/$appSlug/deployments/$deploymentId'

// Create/Update Routes

const WaitlistRoute = WaitlistImport.update({
  id: '/waitlist',
  path: '/waitlist',
  getParentRoute: () => rootRoute,
} as any)

const VerifyUpdateEmailRoute = VerifyUpdateEmailImport.update({
  id: '/verify-update-email',
  path: '/verify-update-email',
  getParentRoute: () => rootRoute,
} as any)

const VerifyEmailRoute = VerifyEmailImport.update({
  id: '/verify-email',
  path: '/verify-email',
  getParentRoute: () => rootRoute,
} as any)

const TeamInvitationRoute = TeamInvitationImport.update({
  id: '/team-invitation',
  path: '/team-invitation',
  getParentRoute: () => rootRoute,
} as any)

const SignupRoute = SignupImport.update({
  id: '/signup',
  path: '/signup',
  getParentRoute: () => rootRoute,
} as any)

const ResetPasswordRoute = ResetPasswordImport.update({
  id: '/reset-password',
  path: '/reset-password',
  getParentRoute: () => rootRoute,
} as any)

const RecoverPasswordRoute = RecoverPasswordImport.update({
  id: '/recover-password',
  path: '/recover-password',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const LayoutRoute = LayoutImport.update({
  id: '/_layout',
  getParentRoute: () => rootRoute,
} as any)

const LayoutIndexRoute = LayoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutSettingsRoute = LayoutSettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutDeviceRoute = LayoutDeviceImport.update({
  id: '/device',
  path: '/device',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutTeamIndexRoute = LayoutTeamIndexImport.update({
  id: '/$team/',
  path: '/$team/',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutTeamsNewRoute = LayoutTeamsNewImport.update({
  id: '/teams/new',
  path: '/teams/new',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutTeamsAllRoute = LayoutTeamsAllImport.update({
  id: '/teams/all',
  path: '/teams/all',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutTeamSettingsRoute = LayoutTeamSettingsImport.update({
  id: '/$team/settings',
  path: '/$team/settings',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutTeamNewAppRoute = LayoutTeamNewAppImport.update({
  id: '/$team/new-app',
  path: '/$team/new-app',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutTeamAppsIndexRoute = LayoutTeamAppsIndexImport.update({
  id: '/$team/apps/',
  path: '/$team/apps/',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutTeamAppsAppSlugIndexRoute = LayoutTeamAppsAppSlugIndexImport.update(
  {
    id: '/$team/apps/$appSlug/',
    path: '/$team/apps/$appSlug/',
    getParentRoute: () => LayoutRoute,
  } as any,
)

const LayoutTeamAppsAppSlugDeploymentsDeploymentIdRoute =
  LayoutTeamAppsAppSlugDeploymentsDeploymentIdImport.update({
    id: '/$team/apps/$appSlug/deployments/$deploymentId',
    path: '/$team/apps/$appSlug/deployments/$deploymentId',
    getParentRoute: () => LayoutRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_layout': {
      id: '/_layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof LayoutImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/recover-password': {
      id: '/recover-password'
      path: '/recover-password'
      fullPath: '/recover-password'
      preLoaderRoute: typeof RecoverPasswordImport
      parentRoute: typeof rootRoute
    }
    '/reset-password': {
      id: '/reset-password'
      path: '/reset-password'
      fullPath: '/reset-password'
      preLoaderRoute: typeof ResetPasswordImport
      parentRoute: typeof rootRoute
    }
    '/signup': {
      id: '/signup'
      path: '/signup'
      fullPath: '/signup'
      preLoaderRoute: typeof SignupImport
      parentRoute: typeof rootRoute
    }
    '/team-invitation': {
      id: '/team-invitation'
      path: '/team-invitation'
      fullPath: '/team-invitation'
      preLoaderRoute: typeof TeamInvitationImport
      parentRoute: typeof rootRoute
    }
    '/verify-email': {
      id: '/verify-email'
      path: '/verify-email'
      fullPath: '/verify-email'
      preLoaderRoute: typeof VerifyEmailImport
      parentRoute: typeof rootRoute
    }
    '/verify-update-email': {
      id: '/verify-update-email'
      path: '/verify-update-email'
      fullPath: '/verify-update-email'
      preLoaderRoute: typeof VerifyUpdateEmailImport
      parentRoute: typeof rootRoute
    }
    '/waitlist': {
      id: '/waitlist'
      path: '/waitlist'
      fullPath: '/waitlist'
      preLoaderRoute: typeof WaitlistImport
      parentRoute: typeof rootRoute
    }
    '/_layout/device': {
      id: '/_layout/device'
      path: '/device'
      fullPath: '/device'
      preLoaderRoute: typeof LayoutDeviceImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/settings': {
      id: '/_layout/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof LayoutSettingsImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/': {
      id: '/_layout/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof LayoutIndexImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/$team/new-app': {
      id: '/_layout/$team/new-app'
      path: '/$team/new-app'
      fullPath: '/$team/new-app'
      preLoaderRoute: typeof LayoutTeamNewAppImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/$team/settings': {
      id: '/_layout/$team/settings'
      path: '/$team/settings'
      fullPath: '/$team/settings'
      preLoaderRoute: typeof LayoutTeamSettingsImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/teams/all': {
      id: '/_layout/teams/all'
      path: '/teams/all'
      fullPath: '/teams/all'
      preLoaderRoute: typeof LayoutTeamsAllImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/teams/new': {
      id: '/_layout/teams/new'
      path: '/teams/new'
      fullPath: '/teams/new'
      preLoaderRoute: typeof LayoutTeamsNewImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/$team/': {
      id: '/_layout/$team/'
      path: '/$team'
      fullPath: '/$team'
      preLoaderRoute: typeof LayoutTeamIndexImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/$team/apps/': {
      id: '/_layout/$team/apps/'
      path: '/$team/apps'
      fullPath: '/$team/apps'
      preLoaderRoute: typeof LayoutTeamAppsIndexImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/$team/apps/$appSlug/': {
      id: '/_layout/$team/apps/$appSlug/'
      path: '/$team/apps/$appSlug'
      fullPath: '/$team/apps/$appSlug'
      preLoaderRoute: typeof LayoutTeamAppsAppSlugIndexImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/$team/apps/$appSlug/deployments/$deploymentId': {
      id: '/_layout/$team/apps/$appSlug/deployments/$deploymentId'
      path: '/$team/apps/$appSlug/deployments/$deploymentId'
      fullPath: '/$team/apps/$appSlug/deployments/$deploymentId'
      preLoaderRoute: typeof LayoutTeamAppsAppSlugDeploymentsDeploymentIdImport
      parentRoute: typeof LayoutImport
    }
  }
}

// Create and export the route tree

interface LayoutRouteChildren {
  LayoutDeviceRoute: typeof LayoutDeviceRoute
  LayoutSettingsRoute: typeof LayoutSettingsRoute
  LayoutIndexRoute: typeof LayoutIndexRoute
  LayoutTeamNewAppRoute: typeof LayoutTeamNewAppRoute
  LayoutTeamSettingsRoute: typeof LayoutTeamSettingsRoute
  LayoutTeamsAllRoute: typeof LayoutTeamsAllRoute
  LayoutTeamsNewRoute: typeof LayoutTeamsNewRoute
  LayoutTeamIndexRoute: typeof LayoutTeamIndexRoute
  LayoutTeamAppsIndexRoute: typeof LayoutTeamAppsIndexRoute
  LayoutTeamAppsAppSlugIndexRoute: typeof LayoutTeamAppsAppSlugIndexRoute
  LayoutTeamAppsAppSlugDeploymentsDeploymentIdRoute: typeof LayoutTeamAppsAppSlugDeploymentsDeploymentIdRoute
}

const LayoutRouteChildren: LayoutRouteChildren = {
  LayoutDeviceRoute: LayoutDeviceRoute,
  LayoutSettingsRoute: LayoutSettingsRoute,
  LayoutIndexRoute: LayoutIndexRoute,
  LayoutTeamNewAppRoute: LayoutTeamNewAppRoute,
  LayoutTeamSettingsRoute: LayoutTeamSettingsRoute,
  LayoutTeamsAllRoute: LayoutTeamsAllRoute,
  LayoutTeamsNewRoute: LayoutTeamsNewRoute,
  LayoutTeamIndexRoute: LayoutTeamIndexRoute,
  LayoutTeamAppsIndexRoute: LayoutTeamAppsIndexRoute,
  LayoutTeamAppsAppSlugIndexRoute: LayoutTeamAppsAppSlugIndexRoute,
  LayoutTeamAppsAppSlugDeploymentsDeploymentIdRoute:
    LayoutTeamAppsAppSlugDeploymentsDeploymentIdRoute,
}

const LayoutRouteWithChildren =
  LayoutRoute._addFileChildren(LayoutRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof LayoutRouteWithChildren
  '/login': typeof LoginRoute
  '/recover-password': typeof RecoverPasswordRoute
  '/reset-password': typeof ResetPasswordRoute
  '/signup': typeof SignupRoute
  '/team-invitation': typeof TeamInvitationRoute
  '/verify-email': typeof VerifyEmailRoute
  '/verify-update-email': typeof VerifyUpdateEmailRoute
  '/waitlist': typeof WaitlistRoute
  '/device': typeof LayoutDeviceRoute
  '/settings': typeof LayoutSettingsRoute
  '/': typeof LayoutIndexRoute
  '/$team/new-app': typeof LayoutTeamNewAppRoute
  '/$team/settings': typeof LayoutTeamSettingsRoute
  '/teams/all': typeof LayoutTeamsAllRoute
  '/teams/new': typeof LayoutTeamsNewRoute
  '/$team': typeof LayoutTeamIndexRoute
  '/$team/apps': typeof LayoutTeamAppsIndexRoute
  '/$team/apps/$appSlug': typeof LayoutTeamAppsAppSlugIndexRoute
  '/$team/apps/$appSlug/deployments/$deploymentId': typeof LayoutTeamAppsAppSlugDeploymentsDeploymentIdRoute
}

export interface FileRoutesByTo {
  '/login': typeof LoginRoute
  '/recover-password': typeof RecoverPasswordRoute
  '/reset-password': typeof ResetPasswordRoute
  '/signup': typeof SignupRoute
  '/team-invitation': typeof TeamInvitationRoute
  '/verify-email': typeof VerifyEmailRoute
  '/verify-update-email': typeof VerifyUpdateEmailRoute
  '/waitlist': typeof WaitlistRoute
  '/device': typeof LayoutDeviceRoute
  '/settings': typeof LayoutSettingsRoute
  '/': typeof LayoutIndexRoute
  '/$team/new-app': typeof LayoutTeamNewAppRoute
  '/$team/settings': typeof LayoutTeamSettingsRoute
  '/teams/all': typeof LayoutTeamsAllRoute
  '/teams/new': typeof LayoutTeamsNewRoute
  '/$team': typeof LayoutTeamIndexRoute
  '/$team/apps': typeof LayoutTeamAppsIndexRoute
  '/$team/apps/$appSlug': typeof LayoutTeamAppsAppSlugIndexRoute
  '/$team/apps/$appSlug/deployments/$deploymentId': typeof LayoutTeamAppsAppSlugDeploymentsDeploymentIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_layout': typeof LayoutRouteWithChildren
  '/login': typeof LoginRoute
  '/recover-password': typeof RecoverPasswordRoute
  '/reset-password': typeof ResetPasswordRoute
  '/signup': typeof SignupRoute
  '/team-invitation': typeof TeamInvitationRoute
  '/verify-email': typeof VerifyEmailRoute
  '/verify-update-email': typeof VerifyUpdateEmailRoute
  '/waitlist': typeof WaitlistRoute
  '/_layout/device': typeof LayoutDeviceRoute
  '/_layout/settings': typeof LayoutSettingsRoute
  '/_layout/': typeof LayoutIndexRoute
  '/_layout/$team/new-app': typeof LayoutTeamNewAppRoute
  '/_layout/$team/settings': typeof LayoutTeamSettingsRoute
  '/_layout/teams/all': typeof LayoutTeamsAllRoute
  '/_layout/teams/new': typeof LayoutTeamsNewRoute
  '/_layout/$team/': typeof LayoutTeamIndexRoute
  '/_layout/$team/apps/': typeof LayoutTeamAppsIndexRoute
  '/_layout/$team/apps/$appSlug/': typeof LayoutTeamAppsAppSlugIndexRoute
  '/_layout/$team/apps/$appSlug/deployments/$deploymentId': typeof LayoutTeamAppsAppSlugDeploymentsDeploymentIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/login'
    | '/recover-password'
    | '/reset-password'
    | '/signup'
    | '/team-invitation'
    | '/verify-email'
    | '/verify-update-email'
    | '/waitlist'
    | '/device'
    | '/settings'
    | '/'
    | '/$team/new-app'
    | '/$team/settings'
    | '/teams/all'
    | '/teams/new'
    | '/$team'
    | '/$team/apps'
    | '/$team/apps/$appSlug'
    | '/$team/apps/$appSlug/deployments/$deploymentId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/login'
    | '/recover-password'
    | '/reset-password'
    | '/signup'
    | '/team-invitation'
    | '/verify-email'
    | '/verify-update-email'
    | '/waitlist'
    | '/device'
    | '/settings'
    | '/'
    | '/$team/new-app'
    | '/$team/settings'
    | '/teams/all'
    | '/teams/new'
    | '/$team'
    | '/$team/apps'
    | '/$team/apps/$appSlug'
    | '/$team/apps/$appSlug/deployments/$deploymentId'
  id:
    | '__root__'
    | '/_layout'
    | '/login'
    | '/recover-password'
    | '/reset-password'
    | '/signup'
    | '/team-invitation'
    | '/verify-email'
    | '/verify-update-email'
    | '/waitlist'
    | '/_layout/device'
    | '/_layout/settings'
    | '/_layout/'
    | '/_layout/$team/new-app'
    | '/_layout/$team/settings'
    | '/_layout/teams/all'
    | '/_layout/teams/new'
    | '/_layout/$team/'
    | '/_layout/$team/apps/'
    | '/_layout/$team/apps/$appSlug/'
    | '/_layout/$team/apps/$appSlug/deployments/$deploymentId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  LayoutRoute: typeof LayoutRouteWithChildren
  LoginRoute: typeof LoginRoute
  RecoverPasswordRoute: typeof RecoverPasswordRoute
  ResetPasswordRoute: typeof ResetPasswordRoute
  SignupRoute: typeof SignupRoute
  TeamInvitationRoute: typeof TeamInvitationRoute
  VerifyEmailRoute: typeof VerifyEmailRoute
  VerifyUpdateEmailRoute: typeof VerifyUpdateEmailRoute
  WaitlistRoute: typeof WaitlistRoute
}

const rootRouteChildren: RootRouteChildren = {
  LayoutRoute: LayoutRouteWithChildren,
  LoginRoute: LoginRoute,
  RecoverPasswordRoute: RecoverPasswordRoute,
  ResetPasswordRoute: ResetPasswordRoute,
  SignupRoute: SignupRoute,
  TeamInvitationRoute: TeamInvitationRoute,
  VerifyEmailRoute: VerifyEmailRoute,
  VerifyUpdateEmailRoute: VerifyUpdateEmailRoute,
  WaitlistRoute: WaitlistRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_layout",
        "/login",
        "/recover-password",
        "/reset-password",
        "/signup",
        "/team-invitation",
        "/verify-email",
        "/verify-update-email",
        "/waitlist"
      ]
    },
    "/_layout": {
      "filePath": "_layout.tsx",
      "children": [
        "/_layout/device",
        "/_layout/settings",
        "/_layout/",
        "/_layout/$team/new-app",
        "/_layout/$team/settings",
        "/_layout/teams/all",
        "/_layout/teams/new",
        "/_layout/$team/",
        "/_layout/$team/apps/",
        "/_layout/$team/apps/$appSlug/",
        "/_layout/$team/apps/$appSlug/deployments/$deploymentId"
      ]
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/recover-password": {
      "filePath": "recover-password.tsx"
    },
    "/reset-password": {
      "filePath": "reset-password.tsx"
    },
    "/signup": {
      "filePath": "signup.tsx"
    },
    "/team-invitation": {
      "filePath": "team-invitation.tsx"
    },
    "/verify-email": {
      "filePath": "verify-email.tsx"
    },
    "/verify-update-email": {
      "filePath": "verify-update-email.tsx"
    },
    "/waitlist": {
      "filePath": "waitlist.tsx"
    },
    "/_layout/device": {
      "filePath": "_layout/device.tsx",
      "parent": "/_layout"
    },
    "/_layout/settings": {
      "filePath": "_layout/settings.tsx",
      "parent": "/_layout"
    },
    "/_layout/": {
      "filePath": "_layout/index.tsx",
      "parent": "/_layout"
    },
    "/_layout/$team/new-app": {
      "filePath": "_layout/$team/new-app.tsx",
      "parent": "/_layout"
    },
    "/_layout/$team/settings": {
      "filePath": "_layout/$team/settings.tsx",
      "parent": "/_layout"
    },
    "/_layout/teams/all": {
      "filePath": "_layout/teams/all.tsx",
      "parent": "/_layout"
    },
    "/_layout/teams/new": {
      "filePath": "_layout/teams/new.tsx",
      "parent": "/_layout"
    },
    "/_layout/$team/": {
      "filePath": "_layout/$team/index.tsx",
      "parent": "/_layout"
    },
    "/_layout/$team/apps/": {
      "filePath": "_layout/$team/apps/index.tsx",
      "parent": "/_layout"
    },
    "/_layout/$team/apps/$appSlug/": {
      "filePath": "_layout/$team/apps/$appSlug/index.tsx",
      "parent": "/_layout"
    },
    "/_layout/$team/apps/$appSlug/deployments/$deploymentId": {
      "filePath": "_layout/$team/apps/$appSlug/deployments/$deploymentId.tsx",
      "parent": "/_layout"
    }
  }
}
ROUTE_MANIFEST_END */
